// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-pallet-graphql-js": () => import("./../src/templates/product-pallet-graphql.js" /* webpackChunkName: "component---src-templates-product-pallet-graphql-js" */),
  "component---src-templates-product-pallet-box-graphql-js": () => import("./../src/templates/product-pallet-box-graphql.js" /* webpackChunkName: "component---src-templates-product-pallet-box-graphql-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plastic-pallet-boxes-index-js": () => import("./../src/pages/plastic-pallet-boxes/index.js" /* webpackChunkName: "component---src-pages-plastic-pallet-boxes-index-js" */),
  "component---src-pages-plastic-pallets-index-js": () => import("./../src/pages/plastic-pallets/index.js" /* webpackChunkName: "component---src-pages-plastic-pallets-index-js" */)
}

